@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Black.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Bold.eot');
  src: local('Whyte Inktrap Bold'), local('WhyteInktrap-Bold'),
      url('assets/font/WhyteInktrap-Bold.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Bold.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Bold.woff') format('woff'),
      url('assets/font/WhyteInktrap-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Black.eot');
  src: local('Whyte Inktrap Black'), local('WhyteInktrap-Black'),
      url('assets/font/WhyteInktrap-Black.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Black.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Black.woff') format('woff'),
      url('assets/font/WhyteInktrap-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Medium.eot');
  src: local('Whyte Inktrap Medium'), local('WhyteInktrap-Medium'),
      url('assets/font/WhyteInktrap-Medium.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Medium.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Medium.woff') format('woff'),
      url('assets/font/WhyteInktrap-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Heavy.eot');
  src: local('Whyte Inktrap Heavy'), local('WhyteInktrap-Heavy'),
      url('assets/font/WhyteInktrap-Heavy.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Heavy.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Heavy.woff') format('woff'),
      url('assets/font/WhyteInktrap-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-ExtraLight.eot');
  src: local('Whyte Inktrap ExtraLight'), local('WhyteInktrap-ExtraLight'),
      url('assets/font/WhyteInktrap-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-ExtraLight.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-ExtraLight.woff') format('woff'),
      url('assets/font/WhyteInktrap-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Whyte Inktrap Super';
  src: url('assets/font/WhyteInktrap-Super.eot');
  src: local('Whyte Inktrap Super'), local('WhyteInktrap-Super'),
      url('assets/font/WhyteInktrap-Super.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Super.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Super.woff') format('woff'),
      url('assets/font/WhyteInktrap-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Thin.eot');
  src: local('Whyte Inktrap Thin'), local('WhyteInktrap-Thin'),
      url('assets/font/WhyteInktrap-Thin.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Thin.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Thin.woff') format('woff'),
      url('assets/font/WhyteInktrap-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Thin_1.eot');
  src: local('Whyte Inktrap Thin'), local('WhyteInktrap-Thin'),
      url('assets/font/WhyteInktrap-Thin_1.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Thin_1.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Thin_1.woff') format('woff'),
      url('assets/font/WhyteInktrap-Thin_1.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Italic.eot');
  src: local('Whyte Inktrap Italic'), local('WhyteInktrap-Italic'),
      url('assets/font/WhyteInktrap-Italic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Italic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Italic.woff') format('woff'),
      url('assets/font/WhyteInktrap-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-ExtraLightItalic.eot');
  src: local('Whyte Inktrap ExtraLight Italic'), local('WhyteInktrap-ExtraLightItalic'),
      url('assets/font/WhyteInktrap-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-ExtraLightItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-ExtraLightItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Whyte Inktrap Book';
  src: url('assets/font/WhyteInktrap-Book.eot');
  src: local('Whyte Inktrap Book'), local('WhyteInktrap-Book'),
      url('assets/font/WhyteInktrap-Book.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Book.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Book.woff') format('woff'),
      url('assets/font/WhyteInktrap-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-ThinItalic.eot');
  src: local('Whyte Inktrap Thin Italic'), local('WhyteInktrap-ThinItalic'),
      url('assets/font/WhyteInktrap-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-ThinItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-ThinItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-BlackItalic.eot');
  src: local('Whyte Inktrap Black Italic'), local('WhyteInktrap-BlackItalic'),
      url('assets/font/WhyteInktrap-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-BlackItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-BlackItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-MediumItalic.eot');
  src: local('Whyte Inktrap Medium Italic'), local('WhyteInktrap-MediumItalic'),
      url('assets/font/WhyteInktrap-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-MediumItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-MediumItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Whyte Inktrap Super';
  src: url('assets/font/WhyteInktrap-SuperItalic.eot');
  src: local('Whyte Inktrap Super Italic'), local('WhyteInktrap-SuperItalic'),
      url('assets/font/WhyteInktrap-SuperItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-SuperItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-SuperItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-SuperItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Whyte Inktrap Super';
  src: url('assets/font/WhyteInktrap-Super_1.eot');
  src: local('Whyte Inktrap Super'), local('WhyteInktrap-Super'),
      url('assets/font/WhyteInktrap-Super_1.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Super_1.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Super_1.woff') format('woff'),
      url('assets/font/WhyteInktrap-Super_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-HeavyItalic.eot');
  src: local('Whyte Inktrap Heavy Italic'), local('WhyteInktrap-HeavyItalic'),
      url('assets/font/WhyteInktrap-HeavyItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-HeavyItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-HeavyItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-BoldItalic.eot');
  src: local('Whyte Inktrap Bold Italic'), local('WhyteInktrap-BoldItalic'),
      url('assets/font/WhyteInktrap-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-BoldItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-BoldItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Whyte Inktrap Super';
  src: url('assets/font/WhyteInktrap-SuperItalic_1.eot');
  src: local('Whyte Inktrap Super Italic'), local('WhyteInktrap-SuperItalic'),
      url('assets/font/WhyteInktrap-SuperItalic_1.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-SuperItalic_1.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-SuperItalic_1.woff') format('woff'),
      url('assets/font/WhyteInktrap-SuperItalic_1.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Light.eot');
  src: local('Whyte Inktrap Light'), local('WhyteInktrap-Light'),
      url('assets/font/WhyteInktrap-Light.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Light.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Light.woff') format('woff'),
      url('assets/font/WhyteInktrap-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Whyte Inktrap Book';
  src: url('assets/font/WhyteInktrap-BookItalic.eot');
  src: local('Whyte Inktrap Book Italic'), local('WhyteInktrap-BookItalic'),
      url('assets/font/WhyteInktrap-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-BookItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-BookItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-LightItalic.eot');
  src: local('Whyte Inktrap Light Italic'), local('WhyteInktrap-LightItalic'),
      url('assets/font/WhyteInktrap-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-LightItalic.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-LightItalic.woff') format('woff'),
      url('assets/font/WhyteInktrap-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'CCCABTPFont';
  src: url('assets/font/WhyteInktrap-Regular.eot');
  src: local('Whyte Inktrap Regular'), local('WhyteInktrap-Regular'),
      url('assets/font/WhyteInktrap-Regular.eot?#iefix') format('embedded-opentype'),
      url('assets/font/WhyteInktrap-Regular.woff2') format('woff2'),
      url('assets/font/WhyteInktrap-Regular.woff') format('woff'),
      url('assets/font/WhyteInktrap-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



.font-ccca{
  font-family: 'CCCABTPFont';
  font-weight: 900;
}

.font-ccca-light{
  font-family: 'CCCABTPFont';
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
